import { parseJSON, format } from 'date-fns'
import {
    mdiCheckboxBlankOutline,
    mdiCheckboxMarkedOutline,
  } from '@mdi/js'

const Boolean = function(value) {
    return (value) ? "Y" : "N";
}

const BooleanIcons = function(status) {
    if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
    return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
}


const DateTime = function(value) {
    if (!value) return "";

    return format(parseJSON(value), "dd-MM-yyyy HH:mm");
}

const DisplayDate = function(value) {
    if (!value) return "";

    return format(new Date(value), "dd-MM-yyyy");
}

const DisplayDateDayInMonth = function(value) {
    if (!value) return "";

    return format(new Date(value), "EEE do LLL");
}

const Time = function(value) {
    if (!value) return "";

    return format(new Date(value), "HH:mm");
}

const Milimeters = function(value) {
    return `${value}mm`
}

const Money = function(value) {
    if (!value && value != 0) return "";
    return `£${value}`
}

export {
    Boolean,
    BooleanIcons,
    DateTime,
    DisplayDate,
    DisplayDateDayInMonth,
    Milimeters,
    Money,
    Time
}